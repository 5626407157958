import React, { useState } from "react";
import classnames from "classnames";
import {
  EyeLogo,
  EyeIcon,
  iIcon,
  doller,
  searchAll,
  searchFilterIcon,
  crossIcon,
  sendMsgIcon,
  downArrow
} from "../../assets/images";
import Tooltip from "../Tooltip";
import "./index.scss";

const TextInput = ({
  label,
  placeholder,
  name,
  tabIndex,
  onFocus,
  value,
  className,
  containerClass,
  onChange,
  onBlur,
  required,
  disabled,
  style,
  innerRef,
  type,
  id,
  signuperrornone,
  error,
  labelFent,
  data,
  phone,
  testimonialTooltip,
  testimonialTooltipPhone,
  accept,
  containerStyle,
  dollerInput,
  onclickserach,
  onKeyPress,
  filterField,
  onKeyUp,
  showCross,
  onCrossClick,
  onSearchClick,
  autoComplete,
  maxLength,
  onInputIconClick,
  dropDownField,
  onClick,
  autoFocus=false
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const onfieldBlur = (e) => {
    e.target.value = e.target.value?.trim()
    if (onBlur) {
      onBlur()
    }
  }
  return (
    <div className={classnames(containerClass, "inputNewWrapper")} style={containerStyle}>
      {label && <p className="formLabel mb-0">{label}</p>}
      {labelFent && <p className="formLabelDropdown mb-0">{labelFent}</p>}
      <div
        className="d-flex align-items-center"
        style={{ position: "relative" }}
      >
        {dollerInput && (
          <img
            src={doller}
            className="dollrActive"
            onClick={() => setShowPassword((prevState) => !prevState)}
            alt=""
          />
        )}
        <input
          type={filterField ? "search" : type === "password" ? (showPassword ? "text" : type) : type}
          required={required}
          value={value && value}
          name={name}
          ref={innerRef}
          className={classnames(
            error
              ? "inputBox form-control inputBoxError"
              : "inputBox form-control inputBoxNormal",
            className
          )}
          style={{
            ...style,
            width: "100%",
          }}
          onFocus={onFocus}
          onClick={onClick}
          onKeyPress={onKeyPress}
          disabled={disabled}
          onChange={onChange && onChange}
          onBlur={onfieldBlur}
          placeholder={placeholder}
          id={id}
          maxLength={maxLength}
          onKeyUp={onKeyUp}
          accept={accept}
          tabIndex={tabIndex}
          autoComplete={autoComplete ? "on" : "off"}
          autoFocus={autoFocus}
        />
        {type === "password" && (
          <img
            src={showPassword ? EyeLogo : EyeIcon}
            className={showPassword ? "eyeLogoActive " : "eyeLogo "}
            onClick={() => setShowPassword((prevState) => !prevState)}
            alt=""
          />
        )}
        {(type === "searchIcon" && !filterField) && (
          <img
            src={searchAll}
            className="searchLogo"
            onClick={onclickserach}
            alt=""
          />

        )}
        {filterField && (
          <img
            src={searchFilterIcon}
            className="searchFilterLogo"
            onClick={onSearchClick}
            alt=""
          />
        )}
        {dropDownField && (
          <div className="agencyDrop" >
            <img
              src={downArrow}
              className="searchFilterLogo"
              alt=""
              onClick={onFocus}
            />
          </div>
        )}
        {type === "sendMessage" && (
          <img
            src={sendMsgIcon}
            className="searchLogo"
            onClick={onInputIconClick}
            alt=""
          />
        )}
        {showCross && (
          <img
            src={crossIcon}
            className="closeLogo"
            onClick={onCrossClick}
            alt=""
          />
        )}
        {testimonialTooltip === true && (
          <div className="ml-2 bigscreentooltip">
            <Tooltip
              content="This is the name of the project you worked on for the client"
              direction="right"
            >
              <img src={iIcon} alt="" />
            </Tooltip>
          </div>
        )}
        {testimonialTooltipPhone === true && (
          <div className="ml-2 mobileView">
            <Tooltip
              content="This is the name of the project you worked on for the client"
              direction="bottom"
            >
              <img src={iIcon} alt="" />
            </Tooltip>
          </div>
        )}
        {phone === true && (
          <div className="ml-2 phoneInfo">
            <Tooltip content={data} direction="bottom">
              <img src={iIcon} alt="" />
            </Tooltip>
          </div>
        )}
        {
          filterField && (
            <img
              src={crossIcon}
              className="crossOutsideIcon"
              onClick={onInputIconClick}
              alt=""
            />
          )
        }
      </div>
      {error && (
        <div>
          <span
            className={
              signuperrornone === true
                ? "d-none"
                : "d-flex my-2"
            }
          >
            <i className="fa fa-exclamation-circle errorIcon" />

            <p className="errorText mb-0 ml-0 ">{error}</p>
          </span>
        </div>
      )}
    </div>
  );
};

export default TextInput;