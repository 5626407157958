/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { addLanguageCircle } from "../../assets/images/index";
import { addLanguagePlus } from "../../assets/images/index";
import {
  GetLanguagesListAPI,
  PostUserLanguagesAPI,
  DeleteUserLanguagesAPI,
  GetUserLanguagesAPI,
} from "../../services/myInformation";
import { GetCurrentAgencyMember, GetUserLoginData } from "../../util/reduxData";
import Modal from "../../components/modal";
import Languages from "./Languages";
import Chips from "../../components/chips/Chips";
import Label from "../label";
import Button from "../button";

import "./languages.scss";

let MainLanguages = ({
  userLanguageList,
  usersProfileView,
  addLanguageModal,
  toggleModal,
}) => {
  let [modal, setModal] = useState(false);
  let [languageAPI, setLanguagesAPI] = useState([]);
  let [languages, setLanguages] = useState([]);
  let [language, setLanguage] = useState({
    name: "",
    proficiency: "",
  });
  let [languageError, setLanguageError] = useState(false);
  let [languageDropdown, setlanguageDropdown] = useState();
  let [languageDropdownProffiency, setlanguageDropdownProffiency] = useState();
  let [editLanguage, setEditLanguage] = useState("");
  const [cantDelete, setCantDelete] = useState(false);
  const userInfo = GetUserLoginData();
  const currentInstrID =
    GetCurrentAgencyMember() || "" === "all" || ""
      ? userInfo?.instrId || ""
      : GetCurrentAgencyMember() || "";

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    getUserLanguages();
  }, [currentInstrID]);

  useEffect(() => {
    setModal(addLanguageModal);
  }, [addLanguageModal]);

  const getLanguages = () => {
    try {
      GetLanguagesListAPI()
        .then((response) => {
          setLanguagesAPI(response.data);
        })
        .catch((err) => {
          console.log(err?.response?.data);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const getUserLanguages = () => {
    try {
      const res = GetUserLanguagesAPI(currentInstrID)
        .then((response) => {
          setLanguages(response.data);
          userLanguageList(response.data);
        })
        .catch((err) => {
          console.log(err?.response?.data);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  let handleLanguage = (e) => {
    setlanguageDropdown(false);
    setLanguageError(false);
    setLanguage({ name: e.value });
  };

  let handleProficiency = (e) => {
    let proficiencyValue =
      e.value === "Basic"
        ? 1
        : e.value === "Conversational"
        ? 2
        : e.value === "Fluent"
        ? 3
        : null;
    setLanguage({
      ...language,
      proficiency: e.value,
      value: proficiencyValue,
    });
    setlanguageDropdownProffiency(false);
    if (editLanguage) {
      setEditLanguage({
        ...editLanguage,
        proficiency: e.value,
      });
    }
  };

  let addLanguage = (e) => {
    let checkExist = languages.filter(
      (lang) => lang.language === language.name
    );

    if (checkExist.length === 1) {
      setLanguageError("Language already added");
    } else if (!language.name || !language.proficiency) {
      if (!language.name && !language.proficiency) {
        setlanguageDropdown("Please select a language");
        setlanguageDropdownProffiency(
          "Select the level of proficiency for the language"
        );
      }
      if (!language.name) {
        setlanguageDropdown("Please select a language");
      } else if (!language.proficiency) {
        setlanguageDropdownProffiency(
          "Select the level of proficiency for the language"
        );
      }
    } else {
      const langObject = {
        language: language.name,
        proficiency: language.value,
        instrId: currentInstrID,
      };
      try {
        PostUserLanguagesAPI(langObject)
          .then(() => {
            setLanguages([...languages, langObject]);
            setModal(false);
            setLanguage({ name: "", proficiency: "", value: "" });
            userLanguageList([...languages, langObject]);
          })
          .catch((err) => {
            console.log(err?.response?.data);
          });
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  let handleLanguageClick = (lang) => {
    setEditLanguage(lang);
    setLanguage(lang);
    setModal(true);
  };

  let handleDelete = (lang, languageCount) => {
    if (languageCount === 1) {
      setCantDelete(true);
      return;
    }
    try {
      DeleteUserLanguagesAPI(lang.language, currentInstrID)
        .then((response) => {
          const updatedArray = languages.filter(
            (data) => data.language !== lang.language
          );
          setLanguages(updatedArray);
          userLanguageList(updatedArray);
        })
        .catch((err) => {
          console.log(err?.response?.data);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  let handleEdit = () => {
    let proficiencyValue =
      language.proficiency === "Basic"
        ? 1
        : language.proficiency === "Conversational"
        ? 2
        : language.proficiency === "Fluent"
        ? 3
        : null;

    let langObject = {
      language: editLanguage.language,
      proficiency: proficiencyValue,
      instrId: currentInstrID,
    };
    try {
      PostUserLanguagesAPI(langObject)
        .then(() => {
          languages.forEach(function (lang) {
            if (lang.language === langObject.language) {
              lang.proficiency = langObject.proficiency;
            }
          });
          setModal(false);
          setLanguage({ name: "", proficiency: "", value: "" });
          setEditLanguage("")
        })
        .catch((err) => {
          console.log(err?.response?.data);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  let handleLanguageProficiency = (value) => {
    if (value === 1) {
      return "Basic";
    } else if (value === 2) {
      return "Conversational";
    } else if (value === 3) {
      return "Fluent";
    }
  };

  const toggleLanguageModal = () => {
    setModal(false);
    if (toggleModal) {
      toggleModal();
    }
    setLanguage({ name: "", proficiency: "", value: "" });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={cantDelete}
        size="extrasmallgoback"
        title={
          <Label label={"Delete Language"} className="font18Black boldFont" />
        }
        titleClass="ml-1"
        hideCloseButton={true}
        toggleModal={() => setCantDelete(false)}
        disableBackdropToggle={true}
      >
        <div className="mx-md-4 mx-2 mt-4 mb-4 text-center">
          <Label
            label={
              <span className="mx-md-0 mx-4 deleteTextPortfolio">
                You need to set at least one Language for your profile.
                <br /> Add another Language on this page to delete the currently
                selected Language.
              </span>
            }
            className="subTextWithoutColorWithoutBold mt-4"
          />
        </div>
        <div
          className="border-top text-center EducationDeletModalButton"
          style={{ left: "0", width: "100%" }}
        >
          <div className="my-3 mb-0">
            <Button
              label="Ok"
              type="submit"
              onClick={() => setCantDelete(false)}
              className="btn-blue main_btn  "
            />
          </div>
        </div>
      </Modal>
      <div>
        <div className={usersProfileView ? "" : "languagesMainSection"}>
          {languages?.length > 0 && (
            <div className={usersProfileView ? "my-1" : "my-3"}>
              {!usersProfileView && (
                <p className="mb-0 languageListSection">Languages*</p>
              )}
              <div className="d-flex mt-1 flex-wrap">
                {languages?.map((lang, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Chips
                        data={languages}
                        languageName={lang.language}
                        languageProficiency={handleLanguageProficiency(
                          lang.proficiency
                        )}
                        onClickEdit={() => handleLanguageClick(lang)}
                        handleDelete={() =>
                          handleDelete(lang, languages?.length)
                        }
                        style={{ marginRight: "16px", marginBottom: "10px" }}
                        language={true}
                        keyValue={lang.name}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
          <div
            className={
              usersProfileView ? "d-none" : "d-flex my-3 align-items-center"
            }
            style={{ cursor: "pointer", width: "fit-content" }}
            onClick={() => {
              setModal(true);
              setEditLanguage(false);
            }}
          >
            <div
              style={{ width: "32px" }}
              className="position-relative d-flex justify-content-center align-items-center iconShadow some"
            >
              <img
                src={addLanguagePlus}
                style={{ width: "12px", position: "absolute", zIndex: "2" }}
                alt=""
              />
              <img src={addLanguageCircle} style={{ width: "32px" }} alt="" />
            </div>
            <p className="addLanguageBtnText mb-0 mx-2">
              {languages.length > 0 ? "Add Additional" : "Add"} Languages
            </p>
          </div>
        </div>
        <div>
          <Modal
            isOpen={modal}
            containerStyle={{
              width: "630px",
              maxWidth: "95%",
              minHeight: "330px",
            }}
            size="small"
            hideCloseButton={true}
            toggleModal={() => toggleLanguageModal()}
            title={editLanguage ? "Edit Language" : "Add Language"}
            disableBackdropToggle={true}
          >
            <div className="mt-3">
              <Languages
                proficiencyTitle="Select Proficiency*"
                title="Select Language*"
                editTitle="Edit Language"
                editProficiencyTitle="Edit Proficiency"
                data={editLanguage}
                error={languageError}
                errorLanguageDropdown={languageDropdown}
                errorlanguageDropdownProffiency={languageDropdownProffiency}
                handleEdit={handleEdit}
                handleLanguage={handleLanguage}
                handleProficiency={handleProficiency}
                addLanguage={addLanguage}
                handleCancel={() => toggleLanguageModal(false)}
                languagesList={languageAPI}
              />
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};
export default MainLanguages;
