/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
    arrowDown,
    CirclePlus,
    CloseCircular,
    fileLogo,
    portfolioDefaultImage,
    optionBox,
    circleUpload,
    Failed,
    leftScrollIcon,
} from "../../assets/images";
import { getProposal, postProposal } from "../../services/submitProposal";
import {
    DownloadDocGet,
    GetCourseAPI,
    GetItemAPI,
    GetVideoAPI,
    UploadDocumentPut,
} from "../../services/portfolio";
import { useGlobalClick } from "../../util/clickListener";
import { GetJobDetailsAPI } from "../../services/jobDetails";
import {
    deleteBlobAPI,
    MyInformationGetAPI,
} from "../../services/myInformation";
import { getFileExtension } from "../../util/getFileExtension";
import { CheckProjectTalentAccess } from "../../services/jobAccess";
import {
    GetReduxMyInfo,
    GetUserLoginData,
} from "../../util/reduxData";
import { trimString } from "../../util/trimString";
import { rateCommentPlaceholder } from "../../constants/placeholders";
import AgencyDropdown from "../../agencyDropdown";
import Label from "../../components/label";
import Modal from "../../components/modal";
import FormTextarea from "../../components/formTextarea/FormTextarea";
import Button from "../../components/button";
import SelectPortfolio from "./selectPortfolio";
import { Toaster } from "../../components/Toaster";
import "./index.scss";

const SubmitProposal = () => {
    const { register, handleSubmit, errors, setValue } = useForm({
        reValidateMode: "onBlur",
    });

    const [jobData, setJobData] = useState({});
    const [proposalDetail, setProposalDetail] = useState({});
    const [openOption, setOpenOption] = useState(false);
    const [selectPortfolio, setSelectPortfolio] = useState(false);
    const [selectedPortfolio, setSelectedPortfolio] = useState([]);
    const [selectedPortfolioId, setSelectedPortfolioId] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [startUploading, setStartUploading] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const [documentsList, setDocumentsList] = useState([]);
    const [totalFileError, setTotalFileError] = useState(false);
    const [openDeleteDocumentModal, setOpenDeleteDocumentModal] =
        useState(false);
    const [deletedDoumentData, setDeletedDoumentData] = useState({});
    const [openRemovePortfolioModal, setOpenRemovePortfolioModal] =
        useState(false);
    const [removedPortfolioData, setRemovedPortfolioData] = useState({});
    const [portfolioItemsToBeRemoved, setPortfolioItemsToBeRemoved] = useState(
        []
    );
    const [existingPortfolioItem, setExistingPortfolioItem] = useState([]);
    const [successModal, setSuccessModal] = useState(false);
    const [inValidFileTypeError, setInValidFileTypeError] = useState(false);
    const [courses, setCourses] = useState([]);
    const [items, setItems] = useState([]);
    const [links, setLinks] = useState([]);
    const [hasAccess, setHasAccess] = useState(0);
    const history = useHistory();
    const ref = useRef();
    const params = useParams();
    const jobId = params.jobId;

    const userInfo = GetUserLoginData();
    const myInfo = GetReduxMyInfo();

    let currentInstrID = new URLSearchParams(window.location.search).get(
        "userId"
    );
    const [agencyMemeber, setAgencyMember] = useState(null);
    const [agencyMyInfo, setAgencyMyInfo] = useState(null);

    useEffect(() => {
        if (history.location.pathname.includes("edit")) {
            checkAccess();
        } else {
            getJobData();
            getAllPortFolio();
            GetPropsalDetail();
        }

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (isAgency) {
            getUserMyInfo();
        }
    }, [agencyMemeber]);

    const getUserMyInfo = () => {
        if (agencyMemeber !== "all") {
            MyInformationGetAPI(agencyMemeber).then((res) => {
                setAgencyMyInfo(res.data);
            });
        }
    };

    const checkAccess = () => {
        CheckProjectTalentAccess(jobId, "ProposalSubmission").then((res) => {
            if (res.data) {
                getJobData();
                getAllPortFolio();
                GetPropsalDetail();
                setHasAccess(1);
            } else {
                setHasAccess(2);
            }
        });
    };

    const [allProposalData, setAllProposalData] = useState(null);

    useEffect(() => {
        if (allProposalData) {
            settingProposalData();
        }
    }, [allProposalData]);

    const settingProposalData = () => {
        const data = allProposalData[currentInstrID];
        if (data) {
            const portfolioItems = {
                data: [
                    ...data?.portfolioLinks,
                    ...data?.portfolioItems,
                    ...data?.portfolioCourses,
                ],
            };
            getThumbnail(portfolioItems, addSelectedPortfolio);
            if (data?.supportingDocuments) {
                setDocumentsList([...data?.supportingDocuments]);
            }
            setProposalDetail(data);
            setValue("rateType", data?.rateType);
        } else {
            setDocumentsList([]);
            setProposalDetail({});
            setValue("rateType", "");
            setSelectedPortfolio([]);
            setExistingPortfolioItem([]);
        }
    };

    const GetPropsalDetail = () => {
        getProposal(jobId).then((res) => {
            setAllProposalData(res.data);
            if (currentInstrID) {
                setAgencyMember(parseInt(currentInstrID));
            }
        });
    };

    const getThumbnail = (response, setFunc) => {
        const linkArray = response.data;
        const newLinkArray = [...linkArray];
        linkArray.forEach((dataDict) => {
            let newDict = {
                ...dataDict,
            };
            if (dataDict.thumbnail && dataDict.urlTypeId !== 13) {
                DownloadDocGet(dataDict?.thumbnail).then((response) => {
                    const Imagedata = `data:${
                        response?.headers["content-type"]
                    };base64,${Buffer.from(response?.data)?.toString(
                        "base64"
                    )}`;
                    newDict.thumbnailImage = Imagedata;
                    const index = linkArray.indexOf(dataDict);
                    if (index > -1) {
                        newLinkArray[index] = newDict;
                    }
                    setFunc([...newLinkArray], true);
                });
            } else {
                const index = linkArray.indexOf(dataDict);
                if (index > -1) {
                    newLinkArray[index] = newDict;
                }
                setFunc([...newLinkArray], true);
            }
        });
        setFunc(linkArray, true);
    };

    const getJobData = () => {
        GetJobDetailsAPI(jobId)
            .then((res) => {
                setJobData(res.data);
                setHasAccess(1);
            })
            .catch((res) => {
                setHasAccess(2);
            });
    };

    const getAllPortFolio = () => {
        getCourses();
        getItems();
        getLinks();
    };

    const setCourseFunc = (data) => {
        setCourses(data);
    };

    const getCourses = () => {
        GetCourseAPI().then((response) => {
            getThumbnail(response, setCourseFunc);
        });
    };

    const setItemsFunc = (data) => {
        setItems(data);
    };

    const getItems = () => {
        GetItemAPI().then((response) => {
            getThumbnail(response, setItemsFunc);
        });
    };

    const setLinkFunc = (data) => {
        setLinks(data);
    };

    const getLinks = () => {
        GetVideoAPI().then((response) => {
            getThumbnail(response, setLinkFunc);
        });
    };

    const getStatuslevel = (status) => {
        let allLevels = {
            Incomplete: 0,
            Good: 1,
            Better: 2,
            Best: 3,
        };
        if (allLevels[status]) {
            return allLevels[status];
        }
        return 0;
    };

    const isAgency = GetUserLoginData().agencyInstrId;

    const checkTalentAccess = () => {
        const info = isAgency ? agencyMyInfo : myInfo;

        if (info?.isICADocSigned) {
            const statusLevel = getStatuslevel(info?.profileStatus);
            return isAgency ? statusLevel > 0 : statusLevel >= 1;
        }

        return false;
    };

    const onSubmit = () => {
        setTotalFileError(false);
        if (checkTalentAccess()) {
            if (!proposalDetail?.rate) {
                return;
            }
            if (!agencyMemeber && userInfo?.agencyInstrId) {
                return;
            }
            const greaterSize = documentsList.filter((data) => {
                if (data.size) {
                    return data;
                }
            });
            if (greaterSize.length > 0) {
                return;
            }
            const proposalPortfolioItems = [];
            const portfolioToBeRemoved = [];
            selectedPortfolio.forEach((res) => {
                proposalPortfolioItems.push({
                    rowID: res.webID || res.courseID || res.docID,
                    tableName: res.tableName,
                });
            });
            portfolioItemsToBeRemoved.forEach((res) => {
                portfolioToBeRemoved.push({
                    rowID: res.webID || res.courseID || res.docID,
                    tableName: res.tableName,
                });
            });
            const payload = {
                projectId: parseInt(jobId),
                talentProposalDescription:
                    proposalDetail.talentProposalDescription,
                rate: proposalDetail.rate,
                rateComments: proposalDetail.rateComments,
                portfolioItemsToBeAdded: proposalPortfolioItems,
                portfolioItemsToBeDeleted: portfolioToBeRemoved,
                instrId: parseInt(agencyMemeber) || userInfo?.instrId,
                prevInstrId: parseInt(currentInstrID || agencyMemeber),
            };
            const promisses = [];
            const documentsToUpload = [...documentsList];
            let index = 0;
            setStartUploading(true);
            for (let document of documentsToUpload) {
                if (document.isNew && !document?.size) {
                    const docData = new FormData();
                    docData.append("formFile", document.binaryDocument);
                    const progressConf = createProgressBar(index, document);
                    const res = UploadDocumentPut(
                        "ProjectInstructor",
                        jobId,
                        docData,
                        progressConf,
                        currentInstrID !== "all"
                            ? agencyMemeber
                            : userInfo.instrId
                    );
                    promisses.push(res);
                }
                index += 1;
            }
            Promise.all(promisses).then(() => {
                postProposal(payload).then(() => {
                    setSuccessModal(true);
                });
            });
        } else {
            Toaster(
                "error",
                "A minimum profile status of Good and a signed ICA document are required in order to submit a proposal."
            );
        }
    };

    const handleClick = (event) => {
        setOpenOption(null);
        if (ref?.current && !ref?.current?.contains(event.target)) {
            setOpenOption(null);
        }
    };

    useGlobalClick(handleClick, ref);

    const embedURL = (url) => {
        if (url?.includes(`vimeo.com/`)) {
            const vimeoPlayerURL = `https://player.vimeo.com/video/`;
            const embedVimeoID = url?.split("/").slice(-1)[0];
            return `${vimeoPlayerURL}${embedVimeoID}`;
        } else {
            const youtubePlayerURL = `//www.youtube.com/embed/`;
            var regExp =
                /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            var match = url.match(regExp);

            if (match && match[2].length === 11) {
                return `${youtubePlayerURL}${match[2]}`;
            } else {
                return "error";
            }
        }
    };

    const addSelectedPortfolio = (data, existing) => {
        setTotalFileError(false);
        selectedPortfolioIdFunc(data);
        const newArray = [];
        data.forEach((res) => {
            const tempDict = { ...res };
            if (res.webID) {
                tempDict.tableName = "InstrURLs";
            }
            if (res.courseID) {
                tempDict.tableName = "InstrCourses";
            }
            if (res.docID && !res.courseID) {
                tempDict.tableName = "InstrDocuments";
            }
            newArray.push(tempDict);
        });
        if (existing) {
            setExistingPortfolioItem([...newArray]);
        } else {
            setSelectedPortfolio([...newArray]);
        }

        setSelectPortfolio(false);
    };

    const sideScroll = (direction, step = 15) => {
        const element = document.getElementById("scrolldiv");
        let scrollAmount = 0;
        if (element) {
            const slideTimer = setInterval(function () {
                if (direction === "left") {
                    element.scrollLeft -= step;
                } else {
                    element.scrollLeft += step;
                }
                scrollAmount += step;
                if (scrollAmount >= 250) {
                    window.clearInterval(slideTimer);
                }
            }, 25);
        }
    };

    const selectedPortfolioIdFunc = (data) => {
        const res = data.map((res) => {
            return res.webID || res.courseID || res.docID;
        });
        setSelectedPortfolioId(res);
    };

    const removePortfolio = (data) => {
        let compareKey = "";

        if (data.tableName === "InstrURLs") {
            compareKey = "webID";
        }
        if (data.tableName === "InstrDocuments") {
            compareKey = "docID";
        }
        if (data.tableName === "InstrCourses") {
            compareKey = "courseID";
        }
        const exisitngPortfolios = existingPortfolioItem.filter(
            (res) => res[compareKey] !== data[compareKey]
        );
        setExistingPortfolioItem(exisitngPortfolios);
        const portfolios = selectedPortfolio.filter(
            (res) => res[compareKey] !== data[compareKey]
        );
        const ids = selectedPortfolioId.filter(
            (res) => res !== data[compareKey]
        );
        setSelectedPortfolioId(ids);
        setSelectedPortfolio(portfolios);
        setPortfolioItemsToBeRemoved([...portfolioItemsToBeRemoved, data]);
        setRemovedPortfolioData({});
        setOpenRemovePortfolioModal(false);
    };

    const createProgressBar = (index, document) => {
        const tempDoc = [...documentsList];
        const progressConfig = {
            onUploadProgress: (progressEvent) => {
                const progress =
                    (progressEvent.loaded / progressEvent.total) * 100;
                let newDict = {
                    ...document,
                    progressBar: progress,
                };
                tempDoc[index] = newDict;
                setDocumentsList([...tempDoc]);
            },
        };
        return progressConfig;
    };

    const handleDocumentUpload = (event) => {
        const allowedExtenstions = [
            "png",
            "jpeg",
            "pdf",
            "docx",
            "pptx",
            "gif",
            "xls",
            "ppt",
            "jpg",
            "xlsx",
            "txt",
            "csv",
        ];
        setUploadCompleted(false);
        const allFile = event.target.files;
        const totalFileCount = allFile.length + documentsList.length;
        if (totalFileCount > 5) {
            setTotalFileError(true);
            return;
        } else {
            setTotalFileError(false);
        }

        let fileArray = [...documentsList];
        for (let file of allFile) {
            const ext = getFileExtension(file.name);
            if (!allowedExtenstions.includes(ext)) {
                setInValidFileTypeError(true);
                return;
            }
            let dataDict = { isNew: true };
            const fileSize = (file.size / 1024 / 1024).toFixed(2);
            if (fileSize > 20) {
                dataDict.size = true;
            }
            dataDict.fileName = file.name;
            dataDict.binaryDocument = file;
            dataDict.fileSize = fileSize * 1000;
            if (JSON.stringify(dataDict) !== JSON.stringify({ isNew: true })) {
                fileArray.unshift(dataDict);
            }
        }
        setInValidFileTypeError(false);
        setDocumentsList(fileArray);
    };

    const deleteUploadedDocument = () => {
        setTotalFileError(false);
        const tempList = [...documentsList];

        if (deletedDoumentData?.isNew) {
            const index = tempList.indexOf(deletedDoumentData);
            if (index > -1) {
                tempList.splice(index, 1);
            }
            setDocumentsList([...tempList]);
            setDeletedDoumentData({});
            setOpenDeleteDocumentModal(false);
        } else {
            deleteBlobAPI(
                deletedDoumentData.documentBlobID,
                "ProjectInstructor"
            ).then((res) => {
                const index = tempList.indexOf(deletedDoumentData);
                if (index > -1) {
                    tempList.splice(index, 1);
                }
                setDocumentsList([...tempList]);
                setDeletedDoumentData({});
                setOpenDeleteDocumentModal(false);
            });
        }
    };

    const deleteDocument = (data) => {
        setDeletedDoumentData(data);
        setOpenDeleteDocumentModal(true);
    };

    const removePortfolioConfirm = (data) => {
        setRemovedPortfolioData(data);
        setOpenRemovePortfolioModal(true);
    };

    const closeDeleteModal = () => {
        setOpenDeleteDocumentModal(false);
        setDeletedDoumentData({});
    };

    const closeRemovePortfolioModal = () => {
        setOpenRemovePortfolioModal(false);
        setRemovedPortfolioData({});
    };

    const getExtension = (filename) => {
        const split = filename.split(".");
        if (split.length > 1) {
            return split[1];
        } else {
            return "--";
        }
    };

    return (
        <>
            {hasAccess === 2 && (
                <>
                    <div className="forbiddenWrapper">
                        <div className="forbiddenBox">
                            <div>
                                <img src={Failed} className="mb-3" alt="" />{" "}
                                <Label
                                    className="font18Black boldFont"
                                    label="Not Found"
                                ></Label>
                                <div className="forbiddenText">
                                    Job Not Found
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {hasAccess === 1 && (
                <>
                    <Modal
                        isOpen={successModal}
                        size="extrasmallgoback"
                        hideCloseButton={true}
                        toggleModal={() => history.push("/jobcenter/3")}
                        disableBackdropToggle={true}
                        containerClass="height-150p"
                    >
                        <div className="text-align-center">
                            <div className="font16Black boldFont mt-2">
                                Your proposal was submitted!
                            </div>
                            <Button
                                label="Ok"
                                className="btn-blue mt-4"
                                onClick={() => {
                                    history.push("/jobcenter/3");
                                }}
                            ></Button>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={openDeleteDocumentModal}
                        size="extrasmall"
                        title={
                            <Label
                                label={"Delete Document"}
                                className="font18Black boldFont"
                            />
                        }
                        titleClass="ml-1"
                        hideCloseButton={true}
                        toggleModal={() => closeDeleteModal()}
                        disableBackdropToggle={true}
                        containerClass="documentDeleteModal"
                    >
                        <div className="modalTextHolder">
                            <Label
                                label={
                                    <span className="mx-md-0 mx-4 deleteTextPortfolio">
                                        Are you sure you want to delete this
                                        document? <br />
                                        <span className="capitalizeWord">
                                            {deletedDoumentData?.fileName}
                                        </span>
                                    </span>
                                }
                                className="subTextWithoutColorWithoutBold mt-4"
                            />
                        </div>
                        <div
                            className="border-top text-center"
                            style={{ left: "0", width: "100%" }}
                        >
                            <div className="buttonHolder">
                                <Button
                                    label="Cancel"
                                    type="button"
                                    onClick={() => closeDeleteModal()}
                                    className="btn-light main_btn mr-2 portfolioButtonDelete"
                                />
                                <Button
                                    label="Delete"
                                    type="submit"
                                    onClick={() => deleteUploadedDocument()}
                                    className="btn-blue main_btn"
                                />
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={openRemovePortfolioModal}
                        size="extrasmall"
                        title={
                            <Label
                                label={"Delete Document"}
                                className="font18Black boldFont"
                            />
                        }
                        titleClass="ml-1"
                        hideCloseButton={true}
                        toggleModal={() => closeRemovePortfolioModal()}
                        disableBackdropToggle={true}
                        containerClass="documentDeleteModal"
                    >
                        <div className="modalTextHolder">
                            <Label
                                label={
                                    <span className="mx-md-0 mx-4 deleteTextPortfolio">
                                        Are you sure you want to remove this
                                        documents?
                                        <br />
                                        <span className="capitalizeWord">
                                            {removedPortfolioData?.title}
                                        </span>
                                    </span>
                                }
                                className="subTextWithoutColorWithoutBold mt-4"
                            />
                        </div>
                        <div
                            className="border-top text-center"
                            style={{ left: "0", width: "100%" }}
                        >
                            <div className="buttonHolder">
                                <Button
                                    label="Cancel"
                                    type="button"
                                    onClick={() => closeRemovePortfolioModal()}
                                    className="btn-light main_btn mr-2 portfolioButtonDelete"
                                />
                                <Button
                                    label="Delete"
                                    type="submit"
                                    onClick={() =>
                                        removePortfolio(removedPortfolioData)
                                    }
                                    className="btn-blue main_btn"
                                />
                            </div>
                        </div>
                    </Modal>
                    {selectPortfolio && (
                        <SelectPortfolio
                            isOpen={selectPortfolio}
                            toggleModal={() =>
                                setSelectPortfolio(!selectPortfolio)
                            }
                            allPortfolioItems={[...links, ...courses, ...items]}
                            setSelectedPortfolios={(data) =>
                                addSelectedPortfolio(data)
                            }
                            selectedPortfolioIdProp={selectedPortfolioId}
                            selectedPortfolioProp={[...selectedPortfolio]}
                            embedURL={(url) => embedURL(url)}
                        />
                    )}

                    <div>
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="jobCenterBox">
                                {userInfo?.agencyInstrId > 0 && (
                                    <div className="innerWrapper">
                                        <div className="blockHeader mt-3">
                                            Proposal From
                                        </div>
                                        <div className="jobDetailWrapper mt-2 mb-4">
                                            <span className="font14Grey">
                                                Select a talent from your
                                                company who you think is
                                                appropriate for this job*
                                            </span>
                                            <div className="mt-3">
                                                <AgencyDropdown
                                                    justDropdown={true}
                                                    hideAddTalent={true}
                                                    agencyMemeber={
                                                        agencyMemeber
                                                    }
                                                    setAgencyMember={(data) =>
                                                        setAgencyMember(data)
                                                    }
                                                    fromSubmitProposal={true}
                                                    hideEveryoneOption={true}
                                                />
                                            </div>
                                            {!agencyMemeber && submitClicked ? (
                                                <div>
                                                    <span className="d-flex align-items-center my-1">
                                                        <i className="fa fa-exclamation-circle errorIcon" />
                                                        <p className="errorText mb-0 ml-0 ">
                                                            Please select a
                                                            talent
                                                        </p>
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                )}
                                <div className="" />
                                <div className="innerWrapper">
                                    <div>
                                        <span className="blockHeader">
                                            Enter Proposal Information
                                        </span>
                                        <div className="font14Black mb-2 jobDetailWrapper">
                                            Provide the details of your proposal
                                            for this job below.
                                        </div>

                                        <div className="jobDetailWrapper mt-3 mb-4">
                                            {userInfo?.agencyInstrId ? (
                                                <span className="boldText">
                                                    Explain why this person is
                                                    the best fit for the
                                                    opportunity*
                                                </span>
                                            ) : (
                                                <span className="boldText ">
                                                    Explain why you are the best
                                                    talent for this project*
                                                </span>
                                            )}
                                            <FormTextarea
                                                maxLength={500}
                                                rows={6}
                                                name="talentProposalDescription"
                                                onChange={(e) => {
                                                    setProposalDetail({
                                                        ...proposalDetail,
                                                        talentProposalDescription:
                                                            e.target.value,
                                                    });
                                                }}
                                                innerRef={register({
                                                    required: true,
                                                })}
                                                containerStyle={{
                                                    marginBottom: 0,
                                                }}
                                                value={
                                                    proposalDetail?.talentProposalDescription ||
                                                    ""
                                                }
                                                placeholder="Briefly share how your experience aligns with the requirements of the job. Emphasize specific skills, knowledge, and accomplishments that make you an excellent fit for the opportunity."
                                                showTextLength={true}
                                                currentLenght={
                                                    proposalDetail
                                                        ?.talentProposalDescription
                                                        ?.length || 0
                                                }
                                                error={
                                                    errors.talentProposalDescription &&
                                                    "A description is Required"
                                                }
                                            ></FormTextarea>
                                        </div>
                                    </div>
                                </div>
                                {/* {
                                    userInfo?.agencyInstrId > 0 &&
                                    <div className="innerWrapper">
                                        <div className="propasalSeperator" />
                                        <div className="blockHeader mt-3">Proposal From</div>
                                        <div className="jobDetailWrapper mt-2 mb-4">
                                            <span className="font14Grey">
                                                Select a talent from your company who you think is appropriate for this job*
                                            </span>
                                            <div className="mt-3">
                                                <AgencyDropdown
                                                    justDropdown={true}
                                                    hideAddTalent={true}
                                                    agencyMemeber={agencyMemeber}
                                                    setAgencyMember={(data) => setAgencyMember(data)}
                                                    fromSubmitProposal={true}
                                                    hideEveryoneOption={true}
                                                />
                                            </div>
                                            {
                                                (!agencyMemeber && submitClicked) ?
                                                    <div>
                                                        <span className="d-flex align-items-center my-1">
                                                            <i className="fa fa-exclamation-circle errorIcon" />
                                                            <p className="errorText mb-0 ml-0 ">
                                                                Please select a talent
                                                            </p>
                                                        </span>
                                                    </div> :
                                                    null
                                            }
                                        </div>
                                    </div>
                                } */}
                                <div className="propasalSeperator" />
                                <div className="innerWrapper">
                                    <div>
                                        <span className="blockHeader">
                                            Your Terms
                                        </span>
                                        <div className="jobDetailWrapper mt-3 mb-4">
                                            <span className="font16Black boldFont mt-2">
                                                Rate Information
                                            </span>
                                            <div className="d-flex mobileDir">
                                                {/* <Controller
                                                    name="rateType"
                                                    control={control}
                                                    defaultValue={proposalDetail?.rateType}
                                                    rules={{ required: true }}
                                                    render={({ onChange, value, name, ref }) => (
                                                        <TextInputDropdown
                                                            label="Rate Type*"
                                                            optionnew={["Daily", "Hourly", "Flat"]}
                                                            id="rateType"
                                                            name="rateType"
                                                            containerClass="mt-2 rateTypeDrop"
                                                            selectedValue={proposalDetail?.rateType}
                                                            onChange={
                                                                (e) => {
                                                                    onChange(e)
                                                                    setProposalDetail({
                                                                        ...proposalDetail,
                                                                        rateType: e.value,
                                                                        rate: null,
                                                                        rateMin: null,
                                                                        rateMax: null
                                                                    })
                                                                    clearErrors("rateType")
                                                                }
                                                            }
                                                            error={errors.rateType && "Rate Type is Required"}
                                                        ></TextInputDropdown>
                                                    )}
                                                /> */}
                                               {jobData?.rateTypeLabel ?
                                                    <div className="mt-2 rateTypeDrop">
                                                        <div className="formLabel mb-0">
                                                            Rate Type
                                                        </div>
                                                        <div className="mt-2 font14Black boldFont">
                                                            {jobData?.rateTypeLabel}
                                                        </div>
                                                    </div>
                                                :""    
                                            }
                                                <div className="minMaxFieldWrap mr-2">
                                                    <Label
                                                        label="Rate*"
                                                        className="formLabel mb-0 mt-2"
                                                    ></Label>
                                                    <NumberFormat
                                                        thousandsGroupStyle="thousand"
                                                        value={
                                                            !proposalDetail?.rate
                                                                ? ""
                                                                : proposalDetail?.rate
                                                        }
                                                        prefix="$"
                                                        displayType="input"
                                                        className="inputBox w-100"
                                                        type="text"
                                                        id={"minRate"}
                                                        placeholder="$0.00"
                                                        allowLeadingZeros={true}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        maxLength="10"
                                                        fixedDecimalScale={true}
                                                        isNumericString={true}
                                                        onChange={() => {}}
                                                        onValueChange={(e) => {
                                                            setProposalDetail({
                                                                ...proposalDetail,
                                                                rate: e.value,
                                                            });
                                                        }}
                                                    />
                                                    {submitClicked &&
                                                        (!proposalDetail?.rate ||
                                                            proposalDetail?.rate ===
                                                                undefined ||
                                                            proposalDetail?.rate ===
                                                                "" ||
                                                            proposalDetail?.rate <=
                                                                0) && (
                                                            <div id="receiveMinError">
                                                                <span className="d-flex align-items-center my-2">
                                                                    <i className="fa fa-exclamation-circle errorIcon" />
                                                                    <p className="errorText mb-0 ml-0 ">
                                                                        Rate
                                                                        must be
                                                                        greater
                                                                        than
                                                                        $0.00 in
                                                                        US
                                                                        Dollars
                                                                    </p>
                                                                </span>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            {parseFloat(
                                                proposalDetail?.rateMin
                                            ) >
                                                parseFloat(
                                                    proposalDetail?.rateMax
                                                ) && (
                                                <div className="">
                                                    <span className="d-flex align-items-center my-2">
                                                        <i className="fa fa-exclamation-circle errorIcon" />
                                                        <p className="errorText mb-0 ml-0 ">
                                                            Maximum Rate should
                                                            be greater than
                                                            Minimum Rate
                                                        </p>
                                                    </span>
                                                </div>
                                            )}
                                            <div className="mt-2">
                                                <FormTextarea
                                                    rows={12}
                                                    name="rateComments"
                                                    label="Rate Comment"
                                                    onChange={(e) => {
                                                        setProposalDetail({
                                                            ...proposalDetail,
                                                            rateComments:
                                                                e.target.value,
                                                        });
                                                    }}
                                                    innerRef={register({
                                                        required: false,
                                                    })}
                                                    maxLength={500}
                                                    currentLenght={
                                                        proposalDetail
                                                            ?.rateComments
                                                            ?.length || 0
                                                    }
                                                    containerStyle={{
                                                        marginBottom: 0,
                                                    }}
                                                    value={
                                                        proposalDetail.rateComments ||
                                                        ""
                                                    }
                                                    placeholder={
                                                        rateCommentPlaceholder
                                                    }
                                                    showTextLength={true}
                                                    error={
                                                        errors.rateComments &&
                                                        "Rate Comment is Required"
                                                    }
                                                ></FormTextarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="propasalSeperator" />

                                <div className="innerWrapper">
                                    <div className="jobDetailWrapper mb-4 ">
                                        <div className="font16Black boldFont">
                                            Supporting Document(s)
                                        </div>
                                        <div className="font14Black boldText mb-2">
                                            Add any related portfolio items to
                                            support your proposals
                                        </div>
                                        {[
                                            ...documentsList,
                                            ...selectedPortfolio,
                                            ...existingPortfolioItem,
                                        ].length > 0 && (
                                            <div className="relative">
                                                <div
                                                    className={
                                                        [
                                                            ...documentsList,
                                                            ...selectedPortfolio,
                                                            ...existingPortfolioItem,
                                                        ].length > 0
                                                            ? "d-block"
                                                            : "d-none"
                                                    }
                                                >
                                                    <div
                                                        className="rightSubmitScroll"
                                                        onClick={() =>
                                                            sideScroll("right")
                                                        }
                                                    >
                                                        <img
                                                            src={leftScrollIcon}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div
                                                        className="leftSubmitScroll"
                                                        onClick={() =>
                                                            sideScroll("left")
                                                        }
                                                    >
                                                        <img
                                                            src={leftScrollIcon}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="portfolioListUserProfileView relative mt-3"
                                                    id="scrolldiv"
                                                >
                                                    {documentsList.map(
                                                        (res, key) => {
                                                            return (
                                                                <div
                                                                    key={key}
                                                                    className="docOutHolder"
                                                                >
                                                                    <div className="documentHolder">
                                                                        <img
                                                                            src={
                                                                                fileLogo
                                                                            }
                                                                            alt=""
                                                                            className="portfoliodocfileLogo"
                                                                        />
                                                                        <span className="extensionName">
                                                                            {getExtension(
                                                                                res.fileName
                                                                            ).toUpperCase()}
                                                                        </span>
                                                                        <div
                                                                            className="deleteIconWrapp"
                                                                            onClick={() =>
                                                                                deleteDocument(
                                                                                    res
                                                                                )
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    CloseCircular
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="normalFont">
                                                                        {
                                                                            res.fileName
                                                                        }
                                                                    </div>
                                                                    {startUploading &&
                                                                        res?.isNew &&
                                                                        res?.progressBar !==
                                                                            0 &&
                                                                        !res.size && (
                                                                            <div
                                                                                className={
                                                                                    uploadCompleted
                                                                                        ? "d-none"
                                                                                        : "text-center"
                                                                                }
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 12,
                                                                                    }}
                                                                                >
                                                                                    Uploading
                                                                                    Document....
                                                                                </span>
                                                                                <div className="profileProgressBar">
                                                                                    <div
                                                                                        className="profileProgressBarPercent"
                                                                                        role="progressbar"
                                                                                        style={{
                                                                                            width: `${res?.progressBar}%`,
                                                                                        }}
                                                                                    ></div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    {res?.size && (
                                                                        <div
                                                                            className={
                                                                                uploadCompleted
                                                                                    ? "d-none"
                                                                                    : "text-center"
                                                                            }
                                                                        >
                                                                            <span className="d-flex align-items-center my-2">
                                                                                <i className="fa fa-exclamation-circle errorIcon" />
                                                                                <p className="errorText mb-0 ml-0 ">
                                                                                    File
                                                                                    Size
                                                                                    is
                                                                                    greater
                                                                                    than
                                                                                    20
                                                                                    MB
                                                                                    Please
                                                                                    Delete
                                                                                    and
                                                                                    Reupload
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                    {[
                                                        ...existingPortfolioItem,
                                                        ...selectedPortfolio,
                                                    ]?.map((res, idx) => {
                                                        return (
                                                            <div
                                                                key={idx}
                                                                className="portfolioHolder"
                                                            >
                                                                {res.urlTypeId ===
                                                                13 ? (
                                                                    <iframe
                                                                        className="portfolioThumbnail"
                                                                        src={embedURL(
                                                                            res?.url
                                                                        )}
                                                                        frameborder="0"
                                                                        allowFullScreen
                                                                        title="thumbnail"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={
                                                                            res?.thumbnailImage
                                                                                ? res?.thumbnailImage
                                                                                : portfolioDefaultImage
                                                                        }
                                                                        alt=""
                                                                        className="portfolioThumbnail"
                                                                    />
                                                                )}
                                                                <div className="normalFont">
                                                                    {trimString(
                                                                        res.title,
                                                                        20
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className="deleteIconWrapp"
                                                                    onClick={() =>
                                                                        removePortfolioConfirm(
                                                                            res
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            CloseCircular
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}

                                        {inValidFileTypeError && (
                                            <div>
                                                <span className="d-flex my-1">
                                                    <i className="fa fa-exclamation-circle errorIcon" />
                                                    <p className="errorText mb-0 ml-0 ">
                                                        File type not allowed
                                                    </p>
                                                </span>
                                            </div>
                                        )}
                                        {totalFileError && (
                                            <div className="">
                                                <span className="d-flex align-items-center my-2">
                                                    <i className="fa fa-exclamation-circle errorIcon" />
                                                    <p className="errorText mb-0 ml-0 ">
                                                        Maximum 5 documents are
                                                        allowed to be uploaded
                                                    </p>
                                                </span>
                                            </div>
                                        )}
                                        <div className="d-flex align-items-center flex-wrap">
                                            <div className="addFilesWrap mt-2">
                                                <img
                                                    src={CirclePlus}
                                                    alt=""
                                                    className="mr-2"
                                                    onClick={() =>
                                                        setOpenOption(
                                                            !openOption
                                                        )
                                                    }
                                                />
                                                <span
                                                    className="font16Blue boldFont"
                                                    onClick={() =>
                                                        setOpenOption(
                                                            !openOption
                                                        )
                                                    }
                                                >
                                                    Add Supporting Files
                                                </span>
                                                <img
                                                    src={arrowDown}
                                                    alt=""
                                                    className="ml-2"
                                                    onClick={() =>
                                                        setOpenOption(
                                                            !openOption
                                                        )
                                                    }
                                                />
                                                <div
                                                    className={
                                                        openOption
                                                            ? "fileoptionCard"
                                                            : "d-none"
                                                    }
                                                >
                                                    <div
                                                        className="fileoption"
                                                        onClick={() =>
                                                            setSelectPortfolio(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={optionBox}
                                                            alt=""
                                                            className="mr-2"
                                                        />
                                                        <span>
                                                            Select from existing
                                                            portfolio
                                                        </span>
                                                    </div>
                                                    <div className="fileoption">
                                                        <input
                                                            type="file"
                                                            name="documents"
                                                            className="d-none"
                                                            id="uploadImg"
                                                            ref={register({
                                                                required: false,
                                                            })}
                                                            onChange={
                                                                handleDocumentUpload
                                                            }
                                                            disabled={
                                                                uploadCompleted
                                                            }
                                                            multiple
                                                        />
                                                        <label htmlFor="uploadImg">
                                                            <img
                                                                src={
                                                                    circleUpload
                                                                }
                                                                alt=""
                                                                className="mr-2"
                                                            />
                                                            <span className="pointer">
                                                                Upload new
                                                                document
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="font12Grey mt-1 ml-3">
                                                Supported File Types: .png,
                                                .jpg, .csv, .xlsx, .docx, .pdf,
                                                .pptx
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="buttonSeperator" />
                                <div className="text-center py-4">
                                    <Button
                                        label="Cancel"
                                        type="button"
                                        onClick={() => history.goBack()}
                                        className="btn-light mx-2 mt-md-0 mt-1"
                                    />
                                    <Button
                                        label={"Submit Proposal"}
                                        type="submit"
                                        onClick={() => setSubmitClicked(true)}
                                        className="btn-blue mx-2 mt-md-0 mt-1"
                                        disabled={startUploading}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </>
    );
};

export default SubmitProposal;
