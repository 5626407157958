import { currency_formatter } from "./currency_formatter";

const unitMapping = {
    "Daily": "/day",
    "Hourly": "/hr",
    "Per Minute": "/minute",
    "Per Word": "/word"
};

const formatRate = (min, max, unit = "") => {
    if (max !== null && max !== 0) {
        return `Min. ${currency_formatter(min)}${unit} to Max. ${currency_formatter(max)}${unit}`;
    }
    
    return `${currency_formatter(min)}${unit}`;
};

export const getRateFromRateType = (type, min, max, cost, rateNegotiable, showUnit) => {
    if ((min === undefined || min === null || min === 0) && (max === undefined || max === null || max === 0) && (cost !== undefined && cost !== null)) {
        min = cost;
        max = null;
    }

    if(rateNegotiable || (min === null && max === null) || (min === 0 && max === 0) || (min === 0 && max === null)) {
        return 'Negotiable';
    }
    
    if (!showUnit || type === "Flat") {
        return formatRate(min, max);
    }
    
    if (unitMapping[type]) {
        return formatRate(min, max, unitMapping[type]);
    }

    return null;
}
