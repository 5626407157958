export const SET_REQUESTED_TALENT = "SET_REQUESTED_TALENT";
export const EMPTY_REQUESTED_TALENT = "EMPTY_REQUESTED_TALENT";

export const initialState = {};

const requestedTalentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_REQUESTED_TALENT:
      return { ...state, ...payload };
    case EMPTY_REQUESTED_TALENT:
      return { ...state };
    default:
      return state;
  }
};

export const setRequestedTalentData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_REQUESTED_TALENT,
      payload,
    });
  };
};

export const setEmptyRequestedTalentData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_REQUESTED_TALENT,
      payload,
    });
  };
};

export default requestedTalentReducer;
