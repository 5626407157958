/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import { convertDate } from "../../../../../util/dateTime";
import { greyStar } from "../../../../../assets/images";

const Reviews = ({ review = [] }) => {

  const [showMore, setShowMore] = useState(false);
  const [reviews, setReviews] = useState([]);  

  useEffect(() => {
    if(review.length>0){
      getReviewData()
    }
  }, [showMore, review])

  const getReviewData = () => {
    if (!showMore) {
      setReviews(review.slice(0, 3))
    } else {
      setReviews(review)
    }
  }

  return (
    <div className="d-flex flex-column paddingForFreelance">
      {reviews?.map((data, idx) => {
        return (
          <div className="mb-2" key={idx}>
            <div className="font18Black boldFont">{data.name || data.projectName}</div>
            <div className="font14Black boldFont">{data?.fname ? `${data?.fname} ${data?.lname?.substring(0, 1)}` : data?.talentName}</div>
            <div className="d-flex align-items-center">
              {/* <span className="mr-1 starRatingNumber">{data.rating}</span> {" "} */}
              <StarRatings
                rating={data.rating}
                starRatedColor="#27D639"
                starHoverColor="#27D639"
                numberOfStars={5}
                name="rating"
                svgIconViewBox="0 0 10 25"
                starDimension="15px"
                starSpacing="0px"
                className='pt-0 mt-0'
                svgIconPath="M2.1 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
              />
            </div>
            <div className='decriptionFeedback'>{data.feedback}</div>
            <div className='dateFeedback'>{convertDate(data.submitteddate || data.createdDate)}</div>
          </div>
        );
      })}
      {
        review.length > 3 ?
          <div className="font14Blue d-flex justify-content-center" onClick={() => setShowMore(!showMore)}>{showMore ? "Less" : "More"}</div>
          : ""
      }
      {
        review?.length === 0 &&
        <>
          <div className="noRecord">
            <div className="text-align-center" >
              <div><img src={greyStar} alt="" /></div>
              <div className="font16Black mt-3 font-weight-bolder">No Reviews Yet</div>
            </div>
          </div>
        </>
      }
    </div>
  );
};
export default Reviews;
