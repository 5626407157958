import React from "react";
import classnames from "classnames";
import {
    rightIcon,
    close,
    moreOption,
    editPencilBlack,
    trashRed,
} from "../../assets/images";
import { useGlobalClick } from "../../util/clickListener";
import "./chips.css";

let Chips = ({
    className,
    onClick,
    label,
    data,
    disabled,
    style,
    language,
    languageName,
    languageProficiency,
    handleDelete,
    onClickEdit,
    keyValue,
    icon,
    size,
    id,
    objdata,
    addIndustryCustomChip,
    rolesObject,
    isUnapprovedSkill,
    onCrossClick,
    labelClass,
    browseSkillChip,
    clickable,
    title,
    tags = false,
    fromTags = false,
    fromDoc = false,
    newTags = false,
    deleteClick,
    editClick,
}) => {
    const ref = React.useRef(null);
    const [openedOption, setOpenedOption] = React.useState(null);

    const handleOutSideClick = () => {
        setOpenedOption(null);
    };

    useGlobalClick(handleOutSideClick, ref);

    const getEllipsisOptions = () => {
        return (
            <div className="pointer relative ml-3 mb-2">
                <img
                    src={moreOption}
                    className={"ml-1 mt-1 optionEllipsis cursor-pointer"}
                    alt=""
                    onClick={() => setOpenedOption(id)}
                ></img>
                <div
                    ref={ref}
                    className={openedOption === id ? "optionCard" : "d-none"}
                >
                    <div className="d-flex option" onClick={editClick}>
                        <img
                            src={editPencilBlack}
                            alt=""
                            className="mr-1 ml-1"
                        />
                        <span className="font12Black">Edit Name</span>
                    </div>
                    <div className="d-flex option" onClick={deleteClick}>
                        <img src={trashRed} alt="" className="mr-1 ml-1" />
                        <span className="font12Red">Delete</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment key={id}>
            {!language && !objdata && !rolesObject && (
                <button
                    className={classnames(
                        "btn chipsBtn d-flex align-items-center text-center",
                        className,
                        fromTags
                            ? "chipBtnActive"
                            : data?.includes(label)
                            ? addIndustryCustomChip
                                ? "chipBtnActiveIndustry text-center"
                                : browseSkillChip && !isUnapprovedSkill
                                ? "browseSkillBtnActive"
                                : browseSkillChip && isUnapprovedSkill
                                ? "browseSkillUnapporovedActive"
                                : "chipBtnActive text-center"
                            : "text-center",
                        size === "small"
                            ? "chipsBtnSmall"
                            : browseSkillChip && !data?.includes(label)
                            ? "browseSkillButton"
                            : "chipsBtn"
                    )}
                    style={{
                        ...style,
                    }}
                    id={id}
                    disabled={disabled}
                    onClick={onClick}
                    type="button"
                    title={title}
                >
                    {clickable ? (
                        <span
                            className={classnames(
                                labelClass,
                                fromTags
                                    ? "newChipsBtnTextActive"
                                    : data?.includes(label)
                                    ? "newChipsBtnTextActive"
                                    : "newChipsBtnText",
                                icon === true ? "ml-auto pl-2" : ""
                            )}
                        >
                            {label}
                        </span>
                    ) : (
                        <span
                            className={classnames(
                                labelClass,
                                data?.includes(label)
                                    ? addIndustryCustomChip
                                        ? "chipsBtnTextActiveIndustry text-center"
                                        : "chipsBtnTextActive text-center"
                                    : "chipsBtnText ml-auto",

                                icon === true ? "ml-auto pl-4" : ""
                            )}
                        >
                            {label}
                        </span>
                    )}
                    {tags ? (
                        <img
                            src={close}
                            className={"ml-auto"}
                            alt=""
                            onClick={onCrossClick}
                            style={{ position: "relative", right: "10px" }}
                        ></img>
                    ) : (
                        ""
                    )}

                    {newTags ? <>{getEllipsisOptions()}</> : ""}

                    {data?.includes(label) &&
                        (icon ? (
                            <img
                                src={rightIcon}
                                className={
                                    addIndustryCustomChip
                                        ? "ml-auto "
                                        : "ml-auto"
                                }
                                alt=""
                                style={{ position: "absolute", right: "10px" }}
                            ></img>
                        ) : browseSkillChip ? (
                            <img
                                src={close}
                                className={""}
                                alt=""
                                onClick={onCrossClick}
                                style={{ position: "absolute", right: "10px" }}
                            ></img>
                        ) : (
                            <img
                                src={close}
                                className={"ml-auto"}
                                alt=""
                                onClick={onCrossClick}
                                style={{ position: "relative", right: "10px" }}
                            ></img>
                        ))}
                </button>
            )}

            {objdata && (
                <button
                    className={classnames(
                        size === "small" ? "chipsBtnSmall" : "chipsBtn",
                        "btn chipsBtn p-0 d-flex justify-content-center align-items-center ",
                        className,

                        objdata.find(
                            (o) =>
                                o.roleId === label.roleId ||
                                o.id === label.roleId
                        )
                            ? "chipBtnActive "
                            : "text-center"
                    )}
                    style={{
                        ...style,
                    }}
                    id={id}
                    disabled={disabled}
                    onClick={onClick}
                    type="button"
                    title={title}
                >
                    <span
                        className={classnames(
                            objdata.find(
                                (o) =>
                                    o.roleId === label.roleId ||
                                    o.id === label.roleId
                            ) && clickable
                                ? "newChipsBtnTextActive justify-content-center text-center"
                                : objdata.find(
                                      (o) =>
                                          o.roleId === label.roleId ||
                                          o.id === label.roleId
                                  ) && !clickable
                                ? "chipsBtnTextActive justify-content-center text-center"
                                : clickable
                                ? "newChipsBtnText"
                                : "chipsBtnText ml-auto",

                            icon === true ? "ml-auto" : "pl-2 pr-2"
                        )}
                    >
                        {label.role}
                    </span>

                    {objdata.find(
                        (o) =>
                            o.roleId === label.roleId || o.id === label.roleId
                    ) &&
                        (icon ? (
                            <img
                                src={rightIcon}
                                className="ml-auto"
                                alt=""
                                style={{ position: "relative", right: "10px" }}
                            ></img>
                        ) : (
                            <img
                                src={close}
                                className="ml-auto"
                                style={{ position: "relative", right: "10px" }}
                                alt=""
                            ></img>
                        ))}
                </button>
            )}

            {rolesObject && (
                <button
                    className={classnames(
                        className,
                        size === "small" ? "chipsBtnSmall" : "chipsBtn",
                        "btn chipsBtn p-0 d-flex justify-content-center align-items-center ",
                        rolesObject.find((o) => o.role === label.role)
                            ? "chipBtnActive "
                            : "text-center"
                    )}
                    style={{
                        ...style,
                    }}
                    id={id}
                    disabled={disabled}
                    onClick={onClick}
                    type="button"
                    title={title}
                >
                    <span
                        className={classnames(
                            rolesObject.find((o) => o.role === label.role)
                                ? "newChipsBtnTextActive justify-content-center text-center"
                                : "newChipsBtnText ml-auto",

                            icon === true ? "ml-auto" : "pl-2 pr-2"
                        )}
                    >
                        {label.role}
                    </span>

                    {rolesObject.find((o) => o.role === label.role) &&
                        (icon ? (
                            <img
                                src={rightIcon}
                                className="ml-auto"
                                style={{ position: "relative", right: "10px" }}
                                alt=""
                            ></img>
                        ) : (
                            <img
                                src={close}
                                className="ml-auto"
                                style={{ position: "relative", right: "10px" }}
                                alt=""
                            ></img>
                        ))}
                </button>
            )}

            {language && (
                <button
                    className={classnames(
                        "btn chipsBtnLanguage px-2 d-flex  align-items-center chipBtnActiveLanguage",
                        className
                    )}
                    style={{
                        ...style,
                    }}
                    disabled={false}
                    type="button"
                    key={keyValue}
                    title={title}
                >
                    <div
                        onClick={onClickEdit}
                        className="d-block w-100 text-left"
                        title="Edit"
                    >
                        <div className="chipsBtnTextLangName">
                            {languageName}
                        </div>
                        <div className="chipsBtnTextLangProficiency">
                            {languageProficiency}
                        </div>
                    </div>
                    <span
                        className="closeIconLanguage ml-auto text-right"
                        onClick={handleDelete}
                    />
                </button>
            )}
        </React.Fragment>
    );
};
export default Chips;
