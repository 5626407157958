import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import { store, persistor } from "./store";
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <ToastContainer />
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
