import axios from "axios";
import { getToken } from "../util/localStorage";
import { updateUserData } from "../ducks/Login";
import refreshAccessToken from "../services/refreshAccessToken";
import { store } from "../store";

let apiVersion = process.env.REACT_APP_API_BASE_URL;
  const instance = axios.create({
    baseURL: apiVersion,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json', 'charset': 'utf-8', "ngrok-skip-browser-warning": true }
  })
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //Commented as per Client request
      // const status = error?.response?.status;
      // if (status === 500) {
      //   // Dispatches a toast notification for 500 server errors
      //   toast.error('An Unknown Error Occurred', {
      //     position: "bottom-right",
      //     autoClose: 5000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme:"dark"
      //     })
      // }
      return Promise.reject(error);
    }
  );
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    config.headers.pageURL = window.location.href;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

let isAlreadyFetchingAccessToken = false;
let requests = [];

const onAccessTokenFetched = (access_token) => {
  requests = requests.filter((callback) => callback(access_token));
};

const addRequest = (callback) => {
  requests.push(callback);
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    const originalRequest = error?.config;
    const { email } = store.getState().auth;
    if (status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        refreshAccessToken({ email })
          .then((res) => {
            let {
              token,
              refreshToken,
              loginId,
              contactId,
              clientId,
              instrId,
              fullName,
              webRole,
              agencyInstrId,
              chatEmail,
              showTrainerRole
            } = res.data;
            const loginReducerData = {
              email: email,
              token: token,
              refreshToken: refreshToken,
              auth: true,
              loginId: loginId,
              contactId: contactId,
              clientId: clientId,
              instrId: instrId,
              fullName: fullName,
              webRole: webRole,
              agencyInstrId: agencyInstrId,
              chatEmail: chatEmail,
              showTrainerRole: showTrainerRole
            };
            const action = updateUserData(loginReducerData);
            store.dispatch(action);
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(token);
          })
          .catch(() => {
            if (window.location.pathname === '/lmiiwatta') return;
            window.location.href = "/logout";
          });
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addRequest((access_token) => {
          originalRequest.headers.Authorization = "Bearer " + access_token;
          resolve(instance.request(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

export default instance;
