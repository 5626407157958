import Axios from "../util/axiosInstance";

export const PostCompanyContact = (payload) => {
    return Axios.post("/api/Contact/Post", payload);
};

export const PutCompanyContact = (payload) => {
    return Axios.put("/api/Contact/Put", payload);
};

export const GetCompanyContact = () => {
    return Axios.get("/api/Contact/GetAll");
};

export const ReInvitePost = (payload) => {
    return Axios.post("/api/Contact/ReInvite", payload);
};

export const DeactivatePost = (payload) => {
    return Axios.put("/api/Contact/DeActivateContact", payload);
};

export const ReactivatePost = (payload) => {
    return Axios.put("/api/Contact/ReActivateContact", payload);
};

export const getRequestedTalentsApi = () => {
    return Axios.get("/api/User/GetJoiningRequestedUsers");
};
