/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
    emailValidations,
    firstNameWithSpace,
    lastNameWithSpace,
} from "../../../../../constants/Validations";
import { emailExistsApi } from "../../../../../services/signup";
import {
    DeactivatePost,
    ReactivatePost,
} from "../../../../../services/companyContact";
import Button from "../../../../../components/button";
import TextInput from "../../../../../components/textInput";
import ReactivateContact from "../activateContact";
import TextInputDropdown from "../../../../../components/textInputDropdownNew";
import DeactivateContact from "../deactivateContact/deactivateContact";
import Modal from "../../../../../components/modal";
import Label from "../../../../../components/label";
import { CloseCircular } from "../../../../../assets/images";
import "./addCompanyContact.scss";

const AddCompanyContact = ({
    toggleModal,
    addCompanyContact,
    companyContactData,
    editCompanyContact,
    existingEmail,
    emailExistErrorProp,
    setProjectContact = () => {},
    setAlreadyAddedContact = () => {},
    handleApproveRequest,
}) => {
    const {
        register,
        errors,
        handleSubmit,
        control,
        clearErrors,
        setError,
        setValue,
    } = useForm({ reValidateMode: "onBlur" });
    const [companyData, setCompanyData] = useState({});
    const [emailExistError, setEmailExistError] = useState(false);
    const [alreadyAddedEmailError, setAlreadyAddedEmailError] = useState(false);
    const [showDeactivateConfirm, setShowDeactivateConfirm] = useState(false);
    const [deactivateContactData, setDeactivateContactData] = useState({});
    const [successfullDeactivate, setSuccessfullDeactivate] = useState(false);
    const [showReactivateConfirm, setShowReactivateConfirm] = useState(false);
    const [reactivateContactData, setReactivateContactData] = useState({});
    const [successfullReactivate, setSuccessfullReactivate] = useState(false);
    const [originalData, setOriginalData] = useState({});
    const [backModalIsOpen, setBackModalIsOpen] = useState(false);

    useEffect(() => {
        setEmailExistError(emailExistErrorProp);
    }, [emailExistErrorProp]);

    const onSubmit = (data) => {
        if (companyData?.newRequest) {
            if (companyData?.webRole !== "") {
                clearErrors("webRole")
                handleApproveRequest(companyData);
            } else {
                setError("webRole", "TTA Connect Access Level is Required");
            }
        } else {
            if (emailExistError) {
                return;
            }
            if (alreadyAddedEmailError) {
                return;
            }
            const finalData = { ...data };
            if (data.webRole === "Administrator") {
                if (companyContactData?.contactID) {
                    editCompanyContact({ ...companyData, webRole: 3 });
                } else {
                    addCompanyContact({ ...finalData, webRole: 3 });
                }
                setProjectContact(null);
                setAlreadyAddedContact(false);
            } else {
                if (companyContactData?.contactID) {
                    editCompanyContact({ ...companyData, webRole: 1 });
                } else {
                    addCompanyContact({
                        ...finalData,
                        webRole: 1,
                    });
                }
                setProjectContact(null);
                setAlreadyAddedContact(false);
            }
        }
    };

    useEffect(() => {
        if (companyContactData.contactID) {
            setCompanyData(companyContactData);
            setValue(
                "webRole",
                companyData.webRole === 1 ? "Administrator" : "Standard User"
            );
            setOriginalData(companyContactData);
        }
    }, [companyContactData, existingEmail]);

    const checkEmailExist = (email) => {
        if (email === companyContactData.email) {
            return;
        }
        if (existingEmail.includes(email)) {
            setAlreadyAddedEmailError(true);
            return;
        } else {
            emailExistsApi(email).then((res) => {
                if (res.data) {
                    setEmailExistError(true);
                } else {
                    setEmailExistError(false);
                }
            });
            return;
        }
    };

    const handleWebRole = (roleId) => {
        if (roleId) {
            if (roleId === 3) {
                return "Administrator";
            } else {
                return "Standard User";
            }
        }
    };

    const showDeactivateConfirmModal = (data) => {
        setDeactivateContactData(data);
        setShowDeactivateConfirm(true);
    };

    const deactivateContact = () => {
        DeactivatePost({
            contactId: deactivateContactData.contactID,
        }).then(() => {
            setDeactivateContactData({});
            setCompanyData({
                ...companyData,
                status: "I",
            });
            setSuccessfullDeactivate(true);
        });
    };

    const toggleDeactivate = () => {
        setShowDeactivateConfirm(false);
        setSuccessfullDeactivate(false);
    };

    const showReactivateConfirmModal = (data) => {
        setReactivateContactData(data);
        setShowReactivateConfirm(true);
    };

    const reactivateContact = () => {
        ReactivatePost({
            contactId: reactivateContactData.contactID,
        }).then((res) => {
            setDeactivateContactData({});
            setCompanyData({
                ...companyData,
                status: "A",
            });
            setSuccessfullReactivate(true);
        });
    };

    const toggleReactivate = () => {
        setShowReactivateConfirm(false);
        setSuccessfullReactivate(false);
    };

    const onCancelClick = () => {
        const difference =
            JSON.stringify(originalData) !== JSON.stringify(companyData);
        if (difference) {
            setBackModalIsOpen(true);
        } else {
            toggleModal();
        }
    };

    const handleSaveChanges = () => {
        setBackModalIsOpen(false);
        handleSubmit(onSubmit)(true);
    };

    return (
        <>
            <Modal
                isOpen={backModalIsOpen}
                size="extrasmallgoback"
                hideCloseButton={true}
                toggleModal={() => {
                    setBackModalIsOpen(false);
                }}
                disableBackdropToggle={true}
            >
                <div className="clientMyinfomodalwrapper">
                    <Label
                        label="Save Changes"
                        className="font18Black boldFont"
                    ></Label>
                    <div className="clientMyinfonomodalDeleteText">
                        <div className="clientMyinfonomodalText">
                            <div>Do you want to save these changes?</div>
                        </div>
                    </div>
                </div>
                <div className="modalButtonHolder">
                    <div className="w-100 mt-2 border-top" />
                    <div className="text-center mt-4 mb-0 float-bottom">
                        <Button
                            label="No"
                            type="btn"
                            onClick={() => {
                                toggleModal();
                            }}
                            className="btn-light mx-2 cancelButtonDelete"
                        />
                        <Button
                            label="Yes"
                            type="btn"
                            className="btn-blue mx-2"
                            onClick={handleSaveChanges}
                        />
                    </div>
                </div>
            </Modal>
            <DeactivateContact
                showDeactivateConfirm={showDeactivateConfirm}
                successfullDeactivate={successfullDeactivate}
                deactivateContactData={deactivateContactData}
                setShowDeactivateConfirm={(data) =>
                    setShowDeactivateConfirm(data)
                }
                deactivateContact={() => deactivateContact()}
                toggleDeactivate={() => toggleDeactivate()}
            />
            <ReactivateContact
                showReactivateConfirm={showReactivateConfirm}
                successfullReactivate={successfullReactivate}
                reactivateContactData={reactivateContactData}
                setShowReactivateConfirm={(data) =>
                    setShowReactivateConfirm(data)
                }
                reactivateContact={() => reactivateContact()}
                toggleReactivate={() => toggleReactivate()}
            />
            <>
                <div>
                    <img
                        src={CloseCircular}
                        alt=""
                        className="modal_close_button"
                        onClick={() => onCancelClick()}
                    />{" "}
                </div>
                <form autoComplete={false} onSubmit={handleSubmit(onSubmit)}>
                    <div className="addCompanyContactFormTopBorder" />
                    <div className="addCompanyContactWrapper">
                        <TextInput
                            name="firstName"
                            innerRef={register({
                                required: true,
                            })}
                            containerClass="mb-3"
                            placeholder="First Name"
                            id="courseTitle"
                            label="First Name*"
                            error={
                                errors.firstName?.type === "required"
                                    ? firstNameWithSpace.errors.required
                                    : errors.firstName?.type === "pattern"
                                    ? firstNameWithSpace.errors.message
                                    : null
                            }
                            value={companyData?.firstName}
                            onChange={(e) => {
                                setCompanyData({
                                    ...companyData,
                                    firstName: e.target.value,
                                });
                            }}
                            disabled={
                                companyData.status === "A" ||
                                companyData.status === "I"
                                    ? true
                                    : false
                            }
                        />
                        <TextInput
                            name="lastName"
                            innerRef={register({
                                required: true,
                            })}
                            containerClass="mb-3"
                            placeholder="Last Name"
                            id="courseTitle"
                            label="Last Name*"
                            error={
                                errors.lastName?.type === "required"
                                    ? lastNameWithSpace.errors.required
                                    : errors.lastName?.type === "pattern"
                                    ? lastNameWithSpace.errors.message
                                    : null
                            }
                            value={companyData?.lastName}
                            onChange={(e) => {
                                setCompanyData({
                                    ...companyData,
                                    lastName: e.target.value,
                                });
                            }}
                            disabled={
                                companyData.status === "A" ||
                                companyData.status === "I"
                                    ? true
                                    : false
                            }
                        />
                        <TextInput
                            name="email"
                            innerRef={register({
                                required: true,
                                pattern: {
                                    value: emailValidations.pattern,
                                    message: emailValidations.errors.invalid,
                                },
                                maxLength: 60,
                            })}
                            error={
                                errors.email?.type === "required"
                                    ? emailValidations.errors.required
                                    : errors.email?.type === "pattern"
                                    ? emailValidations.errors.invalid
                                    : emailExistError
                                    ? "This user already has an account on TTA Connect. Please contact TTA support to add this user to your company account."
                                    : alreadyAddedEmailError
                                    ? "This user has been already added to your company"
                                    : errors.email?.type === "maxLength"
                                    ? "Email address Should not exceed more than 60 character"
                                    : null
                            }
                            containerClass="mb-3"
                            placeholder="Email"
                            id="email"
                            label="Email*"
                            value={companyData?.email}
                            onChange={(e) => {
                                setCompanyData({
                                    ...companyData,
                                    email: e.target.value,
                                });
                                setEmailExistError(false);
                                setAlreadyAddedEmailError(false);
                            }}
                            onBlur={(e) => {
                                checkEmailExist(companyData?.email);
                            }}
                            disabled={
                                companyData.status === "A" ||
                                companyData.status === "I"
                                    ? true
                                    : false
                            }
                        />
                    </div>
                    <div className="ttaAccessBack">
                        <div className="ttaAccessWrapper">
                            <Controller
                                name="webRole"
                                control={control}
                                rules={{ required: true }}
                                defaultValue={handleWebRole(
                                    companyData?.webRole
                                )}
                                render={({ onChange }) => (
                                    <TextInputDropdown
                                        label="TTA Connect Access Level*"
                                        id="webRole"
                                        name="webRole"
                                        containerClass="mt-3"
                                        ttaAccessDetail={true}
                                        optionnew={[
                                            "Administrator",
                                            "Standard User",
                                        ]}
                                        selectedValue={handleWebRole(
                                            companyData?.webRole
                                        )}
                                        onChange={(val) => {
                                            setCompanyData({
                                                ...companyData,
                                                webRole:
                                                    val.value ===
                                                    "Administrator"
                                                        ? 3
                                                        : 1,
                                            });
                                            onChange(val.value);
                                            clearErrors("webRole");
                                        }}
                                        disabled={
                                            companyData.status === "I"
                                                ? true
                                                : false
                                        }
                                        error={
                                            errors.webRole &&
                                            "TTA Connect Access Level is Required"
                                        }
                                    ></TextInputDropdown>
                                )}
                            />
                        </div>
                    </div>
                    {companyContactData?.newRequest ? (
                        ""
                    ) : (
                        <>
                            {companyData?.contactID &&
                                companyData?.status !== "I" &&
                                companyData?.status !== "P" && (
                                    <div className="deactivate">
                                        <span
                                            className="pointer"
                                            onClick={() =>
                                                showDeactivateConfirmModal(
                                                    companyContactData
                                                )
                                            }
                                        >
                                            Deactivate
                                        </span>
                                    </div>
                                )}
                            {companyData?.status === "I" && (
                                <div className="deactivate">
                                    <span
                                        className="pointer"
                                        onClick={() =>
                                            showReactivateConfirmModal(
                                                companyContactData
                                            )
                                        }
                                    >
                                        Reactivate
                                    </span>
                                </div>
                            )}
                            {!companyContactData.contactID && (
                                <div className="contactInfo">
                                    <span>
                                        An link will be sent to this contact to
                                        join your company on TTA Connect.
                                    </span>
                                </div>
                            )}
                        </>
                    )}

                    <div className="border-top mobileButtonWrapper mt-3">
                        <div
                            className="text-center "
                            style={{ minHeight: "70px" }}
                        >
                            <div className="mt-4">
                                <Button
                                    label="Cancel"
                                    type="button"
                                    onClick={onCancelClick}
                                    className="btn-light mx-2"
                                />
                                <Button
                                    label={
                                        companyContactData?.newRequest
                                            ? "Approve"
                                            : companyData.contactID
                                            ? "Save"
                                            : "Invite"
                                    }
                                    type="submit"
                                    className="btn-blue "
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </>
        </>
    );
};

export default AddCompanyContact;
