/* eslint-disable no-unused-vars */
import { logger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import persistConfig from "../config/persist";

import loginReducer, {
  initialState as LoginInitialState,
} from "../ducks/Login";
import informationReducer, {
  initialState as informationInitialState,
} from "../ducks/myInformation";
import completedStepsReducer, {
  initialState as completedStepsState
} from "../ducks/SideMenuLinks"

import authReducer, {
  initialState as authInitialState,
} from "../ducks/Authentication";

import forgetPasswordReducer from "../ducks/ForgetpasswordEmail";

import LetsGetStartedReducer, {
  initialState as letsGetStartedInitialState,
} from "../ducks/LestGetStarted";

import RoleReducer from "../ducks/Roles";

import loginHashReducer from "../ducks/LoginHash";

import CompanyReducer, {
  initialState as companyReducerInitialState,
} from "../ducks/companyInfromation";

import lastVisitedPageReducer, {
  initialState as lastVisitedPageInitailState,
} from "../ducks/lastVisitedPage";

import currentAgencyMemberReducer, {
  initialState as currentAgencyMemberInitialState
} from "../ducks/AgencyMember";

import allAgencyMemberReducer, {
  initialState as allAgencyMemberInitialState
} from "../ducks/AgencyMember/AllAgencyMemberDetail";

import currentAgencyMemberDetailReducer,{
  initialState as currentAgencyMemberDetailInitialState
} from "../ducks/AgencyMember/CurrentAgencyMemeberDetail";

import jobDetailReducer, {
  initialState as jobDetailsInitialState,
} from "../ducks/jobDetails";

import jobDetailTemplateReducer, {
  initialState as jobDetailTemplateReducerInitialState,
} from "../ducks/jobDetails";

import completedJobStepsReducer, {
  initialState as completedJobStepsInitialState
} from "../ducks/JobWizard"

import filesProgressReducer, {
  initialState as filesProgressInitialState
} from "../ducks/uploadProgress"

import backgroudProgressReducer, {
  initialState as backgroudProgressInitialState
} from "../ducks/uploadBackground"

import updateScheduleReducer from "../ducks/Schedule";

import filesTokenReducer from "../ducks/cancelToken"

import cancelFileIndexReducer from "../ducks/cancelledFiles"

import toggleNotificationExitReducer from "../ducks/Notification"
import { deleteLoginInfo } from "../util/localStorage";

import requestedTalentReducer from "../ducks/RequestTalent";

const { NODE_ENV = "development" } = process.env;
const middlewares = [thunk];

if (NODE_ENV === "") {
  middlewares.push(logger);
}

// appReducer
const appReducer = combineReducers({
  loginReducer: loginReducer,
  auth: authReducer,
  forgetPasswordReducer: forgetPasswordReducer,
  LetsGetStartedReducer: LetsGetStartedReducer,
  RoleReducer: RoleReducer,
  CompanyReducer: CompanyReducer,
  loginHashReducer: loginHashReducer,
  informationReducer: informationReducer,
  completedStepsReducer: completedStepsReducer,
  lastVisitedPageReducer: lastVisitedPageReducer,
  jobDetailReducer: jobDetailReducer,
  jobDetailTemplateReducer: jobDetailTemplateReducer,
  completedJobStepsReducer: completedJobStepsReducer,
  filesProgressReducer: filesProgressReducer,
  filesTokenReducer: filesTokenReducer,
  backgroudProgressReducer: backgroudProgressReducer,
  cancelFileIndexReducer: cancelFileIndexReducer,
  updateScheduleReducer: updateScheduleReducer,
  currentAgencyMemberReducer: currentAgencyMemberReducer,
  allAgencyMemberReducer:allAgencyMemberReducer,
  currentAgencyMemberDetailReducer:currentAgencyMemberDetailReducer,
  toggleNotificationExitReducer: toggleNotificationExitReducer,
  requestedTalentReducer: requestedTalentReducer
});

// root reducer - return appReducer
const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    localStorage.removeItem('persist:root')
    return appReducer(undefined, action);
  }
  return appReducer(state, action)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

export { store, persistor };
