import Axios from "../util/axiosInstance";

export const ImageApi = (payload) => {
  return Axios.post("/api/MyInformation/UploadPhoto", payload)
};

export const StateAPI = (payload) => {
  return Axios.get(`/api/Master/States?country=${payload}`)
};

export const MyInformationGetAPI = (instriId) => {
  if (instriId) {
    return Axios.get(`/api/MyInformation/Get?instrId=${instriId}`)
  }
  return Axios.get(`/api/MyInformation/Get`)
};

export const MyInformationPostAPI = (payload) => {
  return Axios.post("/api/MyInformation/Post", payload)
};

export const GetLanguagesListAPI = () => {
  return Axios.get("/api/Master/Languages")
};

export const GetUserLanguagesAPI = (instriId) => {
  return Axios.get("/api/TrainerLanguage/GetAll?instrId=" + instriId)
};

export const PostUserLanguagesAPI = (payload) => {
  return Axios.post("/api/TrainerLanguage/Post", payload)
};

export const DeleteUserLanguagesAPI = (payload, instrId) => {
  return Axios.delete(`/api/TrainerLanguage/Delete?languageToDelete=${payload}&InstrId=${instrId}`)
};

export const deleteBlobAPI = (guid, entityType = null) => {
  return Axios.delete(`/api/Blob/Delete?blobNameGUID=${guid}&entityType=${entityType}`)
}

export const headlineUpdateApi = (payload) => {
  return Axios.post("/api/TrainerProfile/UpdateHeadline", payload)
}

export const  getCompaniesDetailsAPI = (company) => { 
  return Axios.get(`Api/TrainerProfile/GetInstrCompaniesLogoDetails?companyName=${company}`)
}

export const saveTopCompaniesDetailsAPI = (payload) => { 
  return Axios.post("/api/TrainerProfile/SaveInstrCompanyLogo", payload)
}

export const deleteTopCompaniesDetailsAPI = (payload) => { 
  return Axios.put(`/api/TrainerProfile/DeleteInstrCompanyLogo`, payload)
}