import React from 'react';
import ShowMore from "../../../../../components/showMore";
import { convertDate } from '../../../../../util/dateTime';
import { getRateFromRateType } from '../../../../../util/getRateFromRateType';
import { getJobAddressString } from '../../../../../util/jobAddressString';
import "./JobDetails.scss";

const JobDetails = ({
    roleGroup,
    role,
    projectId,
    postedOn,
    JobDescription,
    location,
    TimeZone,
    audienceData,
    RateType,
    rateMin,
    rateMax,
    rateCost,
    rateNegotiable,
    noOfPositions,
    estimatedTime,
    talent,
    showmore = false,
    data
}) => {

    const getTime = (id) => {
        if (id === 1) {
            return "More than 30 hrs/week"
        } else if (id === 2) {

            return "Less than 30hrs/week"
        } else if (id === 3) {
            return "To Be Determined"
        } else {
            return ""
        }
    }

    return (
        <>
            <div className="OpenjobDetailsWrapper">
                <div className='font18Black boldFont mb-3'>Job Details</div>
                <div className="desktopView">
                    <div className="d-flex justify-content-between">
                        {
                            role?.length > 0 &&
                            <div className="d-flex">
                                <span className="font-weight-bold mr-3 noWrap"> Role</span>
                                <div className="jobRoleName">
                                    {role?.map((data, index) => {
                                        return (
                                            <div key={index} className={(talent && !data.role) ? "d-none" : "rolesandratesInactiveChipFreelancer mr-2 mb-2"}>{data.role || "Other"}</div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                        <div className="ml-auto noWrap">
                            <span className="jobIdHeader">Job:</span> <span className="font12Black">{projectId}</span>{" | "}<span className="jobIdHeader">Posted On: </span><span className="font12Black">{convertDate(postedOn)}</span>
                        </div>
                    </div>
                    {JobDescription &&
                        <div className="mt-2">
                            <div className="font-weight-bold mr-3"></div>
                            <ShowMore
                                id="JobDescription"
                                lines={3}
                                textClass="font14Grey"
                                anchorClass="font14Blue"
                                text={JobDescription}
                            />
                        </div>
                    }
                    <hr></hr>
                    <div className="desktopView">
                        <div className='d-flex justify-content-between align-items-start'>
                         {RateType ?  
                                <div>
                                    <div className="font-weight-bold">Rate Type</div>
                                    <div className="font14Grey">
                                        {RateType === "HOURLY" ? "Hourly" : RateType}
                                    </div>
                                </div>
                            :""    
                        }
                            <div>
                                <div className="font-weight-bold">
                                    {`Estimated ${RateType ? RateType : ""} Rate`}
                                </div>
                                <div className="font14Grey">
                                    {getRateFromRateType(RateType, rateMin, rateMax, rateCost, rateNegotiable)}
                                </div>
                            </div>
                            <div>
                                {
                                    estimatedTime ?
                                        <>
                                            <div className="font-weight-bold">Estimated Time Requirement</div>
                                            <div className="font14Grey">
                                                {getTime(estimatedTime)}
                                            </div>
                                        </> :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    {roleGroup !== "C" &&
                        <>
                            <div className="d-flex">
                                {(audienceData?.audienceSize && audienceData?.isAudienceSizeFieldVisible) &&
                                    <div className="w-30 mr-5 mt-2">
                                        <div className="font-weight-bold ">Audience Size</div>
                                        <div className="font14Grey">{audienceData?.audienceSize}</div>
                                    </div>
                                }
                                {
                                    location &&
                                    <div className="w-30 mr-5 mt-2">
                                        <div className="font-weight-bold ">Location</div>
                                        <div className="font14Grey">
                                            {getJobAddressString(data?.projectDetails, true)}
                                        </div>
                                    </div>
                                }

                                {
                                    TimeZone &&
                                    <div className="w-30 mt-2">
                                        <div className="font-weight-bold ">Time Zone</div>
                                        <div className="font14Grey">{TimeZone}</div>
                                    </div>
                                }
                            </div>
                            {
                                audienceData?.description?.length > 0 &&
                                <>
                                    <div className="font-weight-bold mt-4"> Audience Description </div>
                                    <div className="font14Grey">{audienceData?.description}</div>
                                </>
                            }

                        </>
                    }
                </div>

                <div className="mobileView">
                    <div className="d-flex align-items-center">
                        <span className="font-weight-bold mr-3 mt-2"> Role</span>
                        {role?.map((data, index) => {
                            return (
                                <div key={index}>
                                    <div className="rolesandratesInactiveChipFreelancer mt-2">{data.role || "Other"}</div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="jobIdHeaderMobile">
                        <span>Job:{projectId}{" | "}Posted On: {convertDate(postedOn)}</span>
                    </div>
                    {JobDescription &&
                        <div className="mt-4">
                            <ShowMore
                                id="JobDescription"
                                lines={3}
                                textClass="font14Grey"
                                anchorClass="moreText"
                                text={JobDescription}
                            />
                        </div>
                    }
                    <div className="mobileView">
                        <hr></hr>
                        <div>
                            <div className="font-weight-bold mt-2">Rate Type</div>
                            <div className="font14Grey">
                                {RateType === "HOURLY" ? "Hourly" : RateType ? RateType : ""}
                            </div>
                        </div>
                        <div>
                            <div className="font-weight-bold mt-4">Estimated {RateType} Rate</div>
                            <div className="font14Grey">{getRateFromRateType(RateType, rateMin, rateMax, rateCost, rateNegotiable)}</div>
                        </div>

                        <div>
                            <div className="font-weight-bold mt-4">
                                Quantity Of Talent Needed
                            </div>
                            <div className="font14Grey">{noOfPositions}</div>
                        </div>
                        <hr></hr>
                    </div>
                    {roleGroup !== "C" &&
                        <>
                            <div>
                                {(audienceData?.isAudienceSizeFieldVisible) &&
                                    <div className="mt-4">
                                        <div className="font-weight-bold ">Audience Size</div>
                                        <div className="font14Grey">{audienceData?.audienceSize}</div>
                                    </div>
                                }
                                <div className="mt-4">
                                    <div className="font-weight-bold ">Location</div>
                                    <div className="font14Grey">
                                        {getJobAddressString(data?.projectDetails)}
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div className="font-weight-bold ">Timezone</div>
                                    <div className="font14Grey">{TimeZone}</div>
                                </div>

                            </div>
                            {
                                audienceData?.description?.length > 0 &&
                                <>
                                    <div className="font-weight-bold mt-4"> Audience Description </div>
                                    <div className="font14Grey">{audienceData?.description}</div>
                                </>
                            }
                        </>
                    }

                </div>
            </div>

        </>
    )
}

export default JobDetails;