import Axios from "../util/axiosInstance";

export const AddToFavourite = (payload) => {
  return Axios.post(`/api/Company/AddTalentToFavorite`,payload)
};

export const RemoveToFavourite = (payload) => {
  return Axios.put(`/api/Company/RemoveTalentFromFavorite`,payload)
};

export const GetFavourites = (payload) => {
  return Axios.get(`/api/Contact/GetFavorites`,payload)
};