import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import ClientNavbar from "../clientNavbar";
import { noInternet } from "../../assets/images";
import "./index.scss";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught in ErrorBoundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    {this.props?.isClient ? <ClientNavbar /> : <Navbar />}
                    <div className="errorBoundaryBox">
                        <img
                            src={noInternet}
                            className="noInternetImage"
                            alt=""
                        />
                        <p className="font18Black boldFont mb-0">
                            {" "}
                            Something went wrong!
                        </p>
                        <div className="noInternetTextPara">
                            <p className="font16Black">
                                Please try refreshing the page or contact
                                support.
                            </p>
                        </div>
                        <div className="d-flex">
                            <p
                                className="font16Blue"
                                onClick={() =>
                                    window.open(
                                        "https://helpcenter.ttaconnect.com/",
                                        "_blank"
                                    )
                                }
                            >
                                contact support
                            </p>
                            <p
                                className="font16Blue ml-3"
                                onClick={() => window.open("/", "_self")}
                            >
                                go to home
                            </p>
                        </div>
                    </div>
                    <Footer />
                </>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
