import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import $ from "jquery";
import {
  arrowDown,
  CloseCircular,
  crossIcon
} from "../../assets/images";
import Button from "../button";
import "./index.css";

$(".modal").on("hidden.bs.modal", function (e) {
  if ($(".modal").hasClass("in")) {
    $("body").addClass("modal-open");
  }
});

export default class Modal extends Component {

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.firstFocusableElement = React.createRef();
    this.lastFocusableElement = React.createRef();
    this.overlayRef = React.createRef();
  }

  componentDidMount() {
    this.setupFocus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setupFocus();
    }
  }

  componentWillUnmount() {
    // Remove the event listener when the modal is closed
    window.removeEventListener('keydown', this.handleTabKey);
  }

  setupFocus() {
    const { isOpen } = this.props;
    if (isOpen) {
      // Get the first and last focusable elements within the modal
      const focusableElements = this.modalRef.current.querySelectorAll(
        'a, button, input, textarea, select'
      );
      if (focusableElements.length > 0) {
        this.firstFocusableElement.current = focusableElements[0];
        this.lastFocusableElement.current =
          focusableElements[focusableElements.length - 1];

        // Set initial focus to the first focusable element
        this.firstFocusableElement.current.focus();
      }

      // Handle Tab key press to restrict tabbing within the modal
      this.handleTabKey = (e) => {
        if (e.key === 'Tab' && focusableElements.length > 1) {
          if (document.activeElement === this.lastFocusableElement.current && !e.shiftKey) {
            // Tabbing from the last element to the first element
            e.preventDefault();
            this.firstFocusableElement.current.focus();
          } else if (document.activeElement === this.firstFocusableElement.current && e.shiftKey) {
            // Shift+Tabbing from the first element to the last element
            e.preventDefault();
            this.lastFocusableElement.current.focus();
          }
        }
      };

      window.addEventListener('keydown', this.handleTabKey);
    }
  }

  handleKeyDown = (e) => {
    if (this.props.isOpen) {
      if (e.key === 'Tab') {
        const focusableElements = this.modalRef.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements[0];
        const lastElement =
          focusableElements[focusableElements.length - 1];
        if (!e.shiftKey && document.activeElement === lastElement) {
          // Tabbing forward from last element, focus first element
          e.preventDefault();
          firstElement.focus();
        } else if (e.shiftKey && document.activeElement === firstElement) {
          // Tabbing backward from first element, focus last element
          e.preventDefault();
          lastElement.focus();
        }
      }
    }
  };

  render() {
    let {
      isOpen,
      toggleModal,
      disableBackdropToggle,
      hideCloseButton,
      size,
      containerStyle,
      title,
      titleClass,
      containerClass,
      key,
      filter,
      showMinimize,
      overLap,
      className,
      showPrint,
      wrapperStyle
    } = this.props;


    return isOpen ? (
      <div
        className={classnames(
          { modal__wrapperNew: size === "extrasmallgoback" },
          { overLapCss: overLap === true },
          "modal__wrapper",
          className
        )}
        onClick={disableBackdropToggle ? null : toggleModal}
        key={key}
        id={"modalId"}
        ref={this.modalRef}
        style={wrapperStyle}
        onKeyDown={this.handleKeyDown} // Handle keyboard events
      >
        <div
          className={classnames(
            "modal__body",
            { modal__body__small: size === "small" },
            { modal__body__medium: size === "medium" },
            { modal__body__large: size === "large" },
            { modal__body__All_Skills: size === "modalSkills" },
            { modal__body__extralarge: size === "extralarge" },
            { modal__body__browse_Skills: size === "browseSkills" },
            {
              modal__body__extralargeTestimonial:
                size === "extralargeTestimonial",
            },
            {
              modal__body__smallTemplateName: size === "smallTemplateName",
            },
            {
              modal__body__account_companyLocation:
                size === "smallAccountLocation",
            },

            {
              modal__body__extralargeTestimonialConfirm:
                size === "extralargeTestimonialConfirm",
            },
            {
              modal__body__extralargeGetInTOuch:
                size === "extralargeGetInTouch",
            },
            { modal__body__extrasmall: size === "extrasmall" },
            { modal__body__extrasmall1: size === "extrasmall1" },
            { modal__body__extrasmallEmail: size === "extrasmallEmail" },
            {
              modal__body__extrasmall_Delete_Role: size === "extrasmallDelete",
            },
            { modal__body__extrasmallgoback: size === "extrasmallgoback" },
            { modal__body__extrasmallgoback1: size === "extrasmallgoback1" },
            {
              modal__body__extrasmallgobackRedirect:
                size === "extrasmallgobackRedirect",
            },
            {
              modal__body__mediumCompanyContact: size === "mediumCompanyContact",
            },
            {
              modal__body__mediumSchedular: size === "schedularModal",
            },
            {
              modal__body__relatedTask: size === "relatedTask",
            },
            {
              modal__body__duplicate_template_success:
                size === "duplicate_template_success",
            },
            {
              modal__body__extrasmallSkillDelete:
                size === "extrasmallSkillDelete",
            },
            {
              modal__body__invoiceModal: size === "invoiceModal",
            },
            {
              modal__body__blockModal: size === "blockmodal",
            },
            containerClass
          )}
          onClick={(e) => e.stopPropagation()}
          style={containerStyle}
        >
          {title && (
            <p
              className={classnames("mb-0 font18Black boldFont", titleClass)}
              style={{ position: "absolute", top: "15px", left: "20px", marginRight: "40px" }}
            >
              {title}
            </p>
          )}
          {
            showPrint &&
            <Button
              label={"Print"}
              className={"btn-blue printOnModal"}
              id="doNotPrint"
              onClick={() => {
                showPrint()
              }}
            />
          }
          {hideCloseButton && (
            <div className="ml-2 closeModal">
              <img
                className="modal_close_button"
                onClick={toggleModal}
                src={filter ? crossIcon : CloseCircular}
                alt="close"
              />
            </div>
          )}
          {showMinimize && (
            <img
              className="modal_close_button"
              onClick={toggleModal}
              src={arrowDown}
              alt="close"
            />
          )}
          {this.props.children}
        </div>
      </div>
    ) : null;
  }
}

Modal.defaultProps = {
  size: "medium",
  toggleModal: () => { },
  isOpen: false
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  size: PropTypes.oneOf([
    "large", "medium", "small", "extrasmallgoback",
    "extralarge", "modalSkills", "browseSkills",
    "extralargeTestimonial", "smallTemplateName",
    "smallAccountLocation", "extralargeTestimonialConfirm",
    "extralargeGetInTouch", "extrasmall", "extrasmallDelete",
    "extrasmallgoback", "extrasmallgobackRedirect",
    "mediumCompanyContact", "schedularModal",
    "duplicate_template_success", "extrasmallSkillDelete",
    "invoiceModal", "blockmodal", "relatedTask", "extrasmallgoback1",
    "extrasmall1"
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  disableBackdropToggle: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
};
