import React from "react";
import { loadingTransparent } from "../../assets/images";
import "./index.scss";

const FullScreenLoader = () => {
    return (
        <div className="fullscreenLoader">
            <div className="loaderContent">
                <img className="loadingIcon" src={loadingTransparent} alt="" />
                <p className="mb-0 font14Black boldFont">
                    Getting Details... Please Wait
                </p>
            </div>
        </div>
    );
};

export default FullScreenLoader;
